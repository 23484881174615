'use strict';

const { formValidation, clearFormErrors } = require('../components/formValidation');
const errorHelper = require('../utils/errorHelper');

const selectors = {
    resetPasswordForm: '.js-reset-password-form',
    resetPasswordBtn: '.js-reset-password',
    resetPasswordEmail: '#reset-password-email',
    submitEmailBtn: '#submitEmailButton',
    errorContainer: '.js-error-messaging'
};

/**
 * Reset password function
 */
function resetPassword() {
    const { resetPasswordForm, submitEmailBtn } = selectors;
    const $resetForm = $(resetPasswordForm);

    $resetForm.submit(function (e) {
        const $form = $(this);
        e.preventDefault();
        var url = $form.attr('action');
        $form.spinner().start();
        $resetForm.trigger('login:register', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                $form.spinner().stop();
                if (!data.success) {
                    formValidation($form, data);
                } else {
                    $('.request-password-title').text(data.receivedMsgHeading);
                    $('.request-password-body').empty()
                        .append('<p>' + data.receivedMsgBody + '</p>');
                    $(submitEmailBtn).text(data.buttonText)
                        .attr('data-modal', 'closeModal')
                        .attr('data-target', '#requestPasswordResetModal');
                }
            },
            error: function () {
                $form.spinner().stop();
            }
        });
        return false;
    });
}

/**
 * Clear Form function
 */
function clearFormPassword() {
    const { resetPasswordBtn, resetPasswordEmail, resetPasswordForm } = selectors;
    const $form = $(resetPasswordForm);
    const $errorContainer = $form.parent().find(selectors.errorContainer);

    $(resetPasswordBtn).on('modal:beforeClose', function () {
        $(resetPasswordEmail).val('');
        clearFormErrors($form);
        errorHelper.clearErrorNotification($errorContainer);
    });
}

module.exports = {
    resetPassword,
    clearFormPassword
};
