'use strict';

const formValidation = require('../components/formValidation').formValidation;
const errorHelpers = require('../utils/errorHelper');

const selectors = {
    loginForm: '.js-login',
    registerForm: '.js-register'
};

/**
 * Handle account login
 */
function handleLogin() {
    $(selectors.loginForm).submit(function(e) {
        var $form = $(this);
        e.preventDefault();
        const url = $form.attr('action');
        $form.spinner().start();
        $form.trigger('login:submit', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                $form.spinner().stop();
                if (!data.success) {
                    formValidation($form, data);
                    $form.trigger('login:error', data);
                } else {
                    $form.trigger('login:success', data);
                    location.href = data.redirectUrl;
                }
            },
            error: function (data) {
                if (data.responseJSON.redirectUrl) {
                    window.location.href = data.responseJSON.redirectUrl;
                } else {
                    $form.trigger('login:error', data);
                    $form.spinner().stop();
                }
            }
        });
        return false;
    });
}

/**
 * Handles account registration
 */
function handleRegistration() {
    $(selectors.registerForm).submit(function (e) {
        const $form = $(this);
        e.preventDefault();
        const url = $form.attr('action');
        $form.spinner().start();
        $form.trigger('login:register', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                $form.spinner().stop();
                if (!data.success) {
                    $form.trigger('login:register:error', data);
                    formValidation($form, data);
                } else {
                    $form.trigger('login:register:success', data);
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    errorHelpers.createErrorNotification(err.responseJSON.errorMessage);
                }

                $form.spinner().stop();
            }
        });
        return false;
    });
}

module.exports = {
    handleLogin,
    handleRegistration
};
