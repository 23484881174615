'use strict';

const errorHelper = require('../utils/errorHelper');

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearFormErrors(form) {
    const $form = $(form);
    $form.find('.invalid-feedback').empty();
    $form.find('.form-control.is-invalid').removeClass('is-invalid');
}

/**
 *
 * @param {Object} formElement - form element
 * @param {Object} payload - data
 */
function formValidation(formElement, payload) {
    var errorContainer = formElement.parent().find('.js-error-messaging');
    clearFormErrors(formElement);

    errorHelper.clearErrorNotification(errorContainer);
    if (typeof payload === 'object' && payload.fields) {
        Object.keys(payload.fields).forEach(function(key) {
            if (payload.fields[key]) {
                var feedbackElement = $(formElement)
                    .find('[name="' + key + '"]')
                    .parent()
                    .children('.invalid-feedback');
                if (feedbackElement.length > 0) {
                    if (Array.isArray(payload[key])) {
                        feedbackElement.html(payload.fields[key].join('<br/>'));
                    } else {
                        feedbackElement.html(payload.fields[key]);
                    }
                    feedbackElement.siblings('.form-control').addClass('is-invalid');
                }
            }
        });
    }

    if (payload && (payload.error || ('success' in payload && !payload.success))) {
        var errorMsg = '';

        if ('serverError' in payload && payload.serverError && payload.serverError.length > 0) {
            errorMsg = payload.serverError;
        }

        errorHelper.createErrorNotification(errorMsg, errorContainer);
    }
}

module.exports = {
    formValidation,
    clearFormErrors
};
